import React from 'react'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'

const NoUserDisplay = ({ userType }) => {
  return (
    <div className={generalStyles.flexCentered}>
      {userType === 'Speakers' && (
        <span>Agendas and speakers are selected ‘by and for’ community members based on their top priorities and will be available closer to the event.</span>
      )}
      {userType === 'Governing Body Members' && (
        <span>We are building the Governing Body of this new community. If you are a C-level executive who wants to learn more about leading this Gartner C-level Community, contact your Community Program Manager.</span>
      )}
      {/* attendees or if other component uses this default to below */}
      {userType !== 'Speakers' && userType !== 'Governing Body Members' && (
        <span>No {userType} found.</span>
      )}
    </div>
  )
}

export default NoUserDisplay

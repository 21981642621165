import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import Button from 'src/components/Elements/Button'
import elementStyles from './elements.module.scss'

const TypeProps = {
  label: PropTypes.string,
  sublabel: PropTypes.string,
  isSponsor: PropTypes.bool
}

const ExchangeBusinessCardButton = ({ label, sublabel, isSponsor }) => {
  const { eventId } = useParams()
  const navigate = useNavigate()
  return (
    <div className={
      classNames(
        elementStyles.bizCardBtnContainer,
        isSponsor && elementStyles.bizCardBtnContainerSponsor
      )}
    >
      <div
        className={classNames(
          generalStyles.flexRow,
          generalStyles.alignVertical,
          generalStyles.flexApart
        )}
      >
        <div className={elementStyles.bizCardBtnContainerTitle}>
          <span className={fontStyles.bodydemilight}>{label}</span>
          {sublabel && <span className={fontStyles.h3boldlight}>{sublabel}</span>}
          <hr className={elementStyles.hr}></hr>
          <div className={generalStyles.mr20}>
            <Button
              size={'med'}
              color={'solar'}
              onClick={() => navigate(`/programs/${eventId}/scanner${isSponsor ? '?sponsors' : '?attendees'}`)}
              label={'scan'}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

ExchangeBusinessCardButton.propTypes = {
  ...TypeProps
}

export default ExchangeBusinessCardButton

import React from 'react'
import IcomoonReact from 'icomoon-react'
import classNames from 'classnames'
import iconSet from 'src/assets/icons/iconList.json'
import variables from 'src/assets/styles/variables.module.scss'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import agendaStyles from '../../agendaStyles.module.scss'

// itemId: String/Integer
// updateRating: Method
// rating: Integer
const StarRating = ({ itemId, updateRating, rating, refId }) => {
  return (
    <div
      className={classNames(
        generalStyles.flexRow,
        generalStyles.flexAround,
        generalStyles.mt20,
        generalStyles.mb40
      )}
    >
      {[...Array(5)].map((x, i) => {
        i++
        return (
          <button
            key={`star-${itemId}-${i}`}
            data-testid={`star-${refId}-${i}`}
            onClick={() => {
              updateRating(i, itemId)
            }}
            className={agendaStyles.starBtn}
          >
            <IcomoonReact
              size={20}
              iconSet={iconSet}
              color={rating >= i ? variables.evantaBlue : variables.darkGrey}
              icon={
                rating >= i
                  ? 'Control-StarIndicator-BlueSolid'
                  : 'Control-StarIndicator-BlueOutline'
              }
            />
          </button>
        )
      })}
    </div>
  )
}

export default StarRating

import React from 'react'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { useAnalyticsTracking } from 'src/utils/GoogleAnalytics'
import { useGlobalContext } from 'src/stores/GlobalContext'
import { isEmpty } from 'lodash'
import UserRow from 'src/components/Elements/UserRow'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import HorizontalLine from 'src/components/Elements/HorizontalLine'
import useFetchSponsors from 'src/queries/sponsors/useFetchSponsors'
import agendaStyles from './agendaStyles.module.scss'
import P2PView from './components/P2PView'
import useFetchSpeakers from 'src/queries/speakers/useFetchSpeakers'

const AgendaDetail = ({ currentEvent, session }) => {
  const navigate = useNavigate()
  const googleTracking = useAnalyticsTracking()
  const { sponsors } = useFetchSponsors()
  const { speakers } = useFetchSpeakers()
  const { attendee, attendees, isLoadingAttendees } = useGlobalContext()

  const renderSessionDescription = () => {
    if (session.session_type_key === 'session_type_one_to_one_meeting') {
      return (
        <div className={classNames(generalStyles.mt20, generalStyles.mb20)}>
          <span>
            IMPORTANT: Meetings are subject to change; please refresh this app
            to see the latest updates.
          </span>
        </div>
      )
    } else {
      return <div dangerouslySetInnerHTML={{ __html: session?.body }} />
    }
  }
  const renderSpeaker = (speaker) => {
    if (!isLoadingAttendees) {
      const user = attendees.find((a) => a.user_id === speaker.user_id)
      const speakerUser = speakers.find((spkr) => spkr.user_id === speaker.user_id)
      return (
        <button
          key={speaker.id}
          className={classNames(generalStyles.userRowButton, generalStyles.mt10)}
          disabled={!user?.is_attended || !currentEvent?.is_attended}
          onClick={() =>
            navigate(`/programs/${currentEvent?.id}/attendees/${user?.user_id}`)
          }
        >
          <UserRow
            textContent={'speaker'}
            user={speakerUser}
            isSmallPhoto={true}
            speakerType={speaker.speaker_label}
          />
        </button>
      )
    }
  }
  const renderP2PMeetings = () => {
    if (
      session?.session_type_key === 'session_type_one_to_one_meeting' &&
      attendee?.is_approved &&
      currentEvent?.p2p_information
        ?.external_1_on_1_platform_selections_is_complete
    ) {
      const timeslots = session?.one_on_one_meetings.map((mtg) => {
        const p2pUser = attendees.find(
          (a) => a?.user_id === mtg?.participant_user_id
        )
        return (
          <div key={mtg?.id} className={agendaStyles.p2pRow}>
            <div className={agendaStyles.p2pContents}>
              <div
                className={classNames(generalStyles.mt10, generalStyles.mb10)}
              >
                <HorizontalLine color={'lightGrey'} fullWidth={true} />
              </div>

              <button
                onClick={() =>
                  navigate(
                    `/programs/${currentEvent?.id}/attendees/${p2pUser?.user_id}`
                  )
                }
                disabled={!p2pUser}
                className={classNames(
                  { [generalStyles.userRowButton]: p2pUser },
                  generalStyles.leftAlignedText,
                  generalStyles.flexColumn
                )}
              >
                <P2PView meeting={mtg} p2pUser={p2pUser} />
              </button>
            </div>
          </div>
        )
      })
      return <>{timeslots}</>
    }
  }

  const renderAttachments = () => {
    const attachments = session.attachments.map((a) => {
      return (
        <div key={a.id} className={classNames(generalStyles.mt10)}>
          <a
            href={a.url}
            target="_blank"
            rel="noreferrer"
            className={fontStyles.bodydemiblue}
            onClick={() =>
              googleTracking('resource_link', 'event_page', {
                attachment_id: a.id.toString()
              })
            }
          >
            {a?.title}
          </a>
        </div>
      )
    })
    return (
      <>
        <div className={generalStyles.mt20}>
          <span className={fontStyles.bodybolddark}>Resources</span>
        </div>
        <div className={classNames(generalStyles.mt10, generalStyles.mb20)}>
          <HorizontalLine fullWidth color='lightGrey' />
        </div>
        {attachments}
      </>
    )
  }

  const renderSessionSponsor = () => {
    const partner = sponsors.find((p) => p.id === session.sponsor_id) || {}
    if (!partner.is_hidden) {
      return (
        <>
          <div className={generalStyles.mt40}>
            <span className={fontStyles.bodybolddark}>Hosted by</span>
          </div>
          <div className={classNames(generalStyles.mt10, generalStyles.mb20)}>
            <HorizontalLine fullWidth={true} color={'lightGrey'} />
          </div>
          <button
            onClick={() =>
              navigate(`/programs/${currentEvent?.id}/sponsors/${partner?.id}`)
            }
            className={generalStyles.p0}
          >
            <div className={agendaStyles.partnerLogoContainer}>
              <img src={partner?.logo} className={agendaStyles.partnerLogo} />
            </div>
          </button>
        </>
      )
    }
  }

  if (isEmpty(session)) return <></>

  return (
    <div className={generalStyles.pb40}>
      {session.event_session_speakers.map((speaker) => {
        return renderSpeaker(speaker)
      })}
      {renderSessionDescription()}
      {session.one_on_one_meetings?.length > 0 && renderP2PMeetings()}
      {session.sponsor_id && renderSessionSponsor()}
      {session.attachments?.length > 0 &&
        currentEvent.is_attended &&
        renderAttachments()}
    </div>
  )
}

export default AgendaDetail

import React, { useCallback, useState, useRef } from 'react'
import IcomoonReact from 'icomoon-react'
import iconSet from 'src/assets/icons/iconList.json'
import useClickOutside from 'src/hooks/useClickOutside'
import MainNavigation from './MainNavigation'
import navStyles from './navigation.module.scss'

const MobileNavigation = () => {
  const clickRef = useRef()
  const [isOpen, setIsOpen] = useState(false)

  const toggleNav = useCallback(() => {
    setIsOpen(!isOpen)
  }, [isOpen])

  useClickOutside(clickRef, () => setIsOpen(false))

  return (
    <div ref={clickRef} className={navStyles.mobileNavigationContainer}>
      <IcomoonReact
        iconSet={iconSet}
        className={navStyles.logo}
        size={100}
        icon="App-GCCLogo-Small-Colour"
      />
      <div onClick={toggleNav}>
        <IcomoonReact
          iconSet={iconSet}
          className={navStyles.logo}
          size={20}
          icon="Control-HamburgerMenu-Blue"
        />
      </div>
      {isOpen && (
        <div className={navStyles.mobileNavigationDrawer}>
          <MainNavigation isMobile toggleMainNav={toggleNav} />
        </div>
      )}
    </div>
  )
}

export default MobileNavigation

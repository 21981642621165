import React from 'react'
import { createBrowserRouter, redirect } from 'react-router-dom'
// general
import ContactUsForm from 'src/components/Elements/ContactUsForm'
import ErrorPage from 'src/components/Elements/ErrorPage'
import SplashLayout from 'src/components/Layout/SplashLayout'

// prelogin
import PreLoginLayout from 'src/components/Layout/PreLoginLayout'
import SignInForm from 'src/features/prelogin/SignInForm'
import SignUpForm from 'src/features/prelogin/SignUpForm'
import HelpAndSupport from 'src/features/prelogin/HelpAndSupport'
import ForgotPassword from 'src/features/prelogin/ForgotPassword'
import ChangeTempPasswordForm from 'src/features/prelogin/ChangeTempPasswordForm'
import ProfileForm from 'src/features/prelogin/ProfileForm'
import QualificationCriteria from 'src/features/prelogin/QualificationCriteria'
import DetailedContactUsForm from 'src/features/prelogin/DetailedContactUsForm'

// logged in
// import HomePage from '../features/home/HomePage'
// import CommunityPage from '../features/community/CommunityPage'
// import InsightsPage from '../features/insights/InsightsPage'
// import NotificationsPage from '../features/notifications/NotificationsPage'
// import SearchPage from '../features/search/SearchPage'
// import DirectoryPage from '../features/directory/DirectoryPage'
import MainLayout from 'src/components/Layout/MainLayout'
import ProgramsLayout from 'src/components/Layout/ProgramsLayout'
import ProfilePage from 'src/features/settings/ProfilePage'
import EditProfilePage from 'src/features/settings/EditProfilePage'
import EditPhotoPage from 'src/features/settings/EditPhotoPage'
import SettingsPage from 'src/features/settings/SettingsPage'
import BookmarkedPage from 'src/features/settings/BookmarkedPage'

// programs
import ProgramsHomePage from 'src/features/programs/home/ProgramsHomePage'
import ProgramHomePage from 'src/features/programs/program/home/ProgramHomePage'
import AgendaIndex from 'src/features/programs/program/agenda/AgendaIndex'
import Leaderboard from 'src/features/programs/program/leaderboard/Leaderboard'
import LeaderboardRanking from 'src/features/programs/program/leaderboard/LeaderboardRanking'
import PartnerList from 'src/features/programs/program/partners/PartnerList'
import PartnerDetailPage from 'src/features/programs/program/partners/PartnerDetailPage'
import SupportPage from 'src/features/programs/program/support/SupportPage'
import UserDetailPage from 'src/features/programs/program/users/UserDetailPage'
import EventEvaluation from 'src/features/programs/program/evaluations/EventEvaluation'

import Cookies from 'js-cookie'
import ThreadIndex from 'src/features/messages/ThreadIndex'
import AttendeeIndex from 'src/features/programs/program/users/AttendeeIndex'
import GoverningBodyIndex from 'src/features/programs/program/users/GoverningBodyIndex'
import SpeakerIndex from 'src/features/programs/program/users/SpeakerIndex'
import ImageUploader from 'src/features/settings/components/ImageUploader'
import CameraUploader from 'src/features/settings/components/CameraUploader'
import ImageEditor from 'src/features/settings/components/ImageEditor'
import Scanner from 'src/features/programs/program/users/Scanner'
import QRCodePage from 'src/features/programs/program/users/QRCodePage'

// P2P Wizard
import Wizard from 'src/features/wizard/WizardPage'
import VirtualPortal from 'src/features/virtualPortal/index'

import { useRedirect } from 'src/routes/useRedirect'

const getAuthToken = () => {
  const token = Cookies.get('token') || null
  // const tokenDuration = getTokenDuration()
  // if (tokenDuration < 0) {
  //   return 'EXPIRED'
  // }
  return token
}

const tokenLoader = () => {
  useRedirect()
  return getAuthToken()
}

const checkAuthLoader = () => {
  const token = getAuthToken()
  useRedirect()
  if (!token) {
    return redirect('/auth/signin')
  }
  return null
}

// const getTokenDuration = () => {
//   const storedExpirationDate = Cookies.get('tokenExpiration')
//   const expirationDate = new Date(storedExpirationDate)
//   const now = new Date()
//   const duration = expirationDate.getTime() - now.getTime()
//   return duration
// }

export const router = createBrowserRouter([
  {
    path: '/auth',
    element: <SplashLayout />,
    errorElement: <ErrorPage />,
    id: 'root',
    loader: tokenLoader,
    children: [
      {
        element: <PreLoginLayout />,
        children: [
          {
            path: 'signin',
            element: <SignInForm />
          },
          {
            path: 'signup',
            element: <SignUpForm />
          },
          {
            path: 'profile',
            element: <ProfileForm />
          },
          {
            path: 'qualifications',
            element: <QualificationCriteria />
          },
          {
            path: 'forgot-password',
            element: <ForgotPassword />
          },
          {
            path: 'update-password',
            element: <ChangeTempPasswordForm />
          },
          {
            path: 'update-password/:token',
            element: <ChangeTempPasswordForm />
          },
          {
            path: 'help',
            element: <HelpAndSupport />
          },
          {
            path: 'contact',
            element: <DetailedContactUsForm />
          }
        ]
      }
    ]
  },
  {
    path: '',
    element: <MainLayout />,
    loader: checkAuthLoader,
    errorElement: <ErrorPage />,
    children: [
      // {
      //   // index: true,
      //   path: 'home',
      //   element: <HomePage />
      // },
      // {
      //   path: 'communities',
      //   element: <CommunityPage />
      // },
      // {
      //   path: 'insights',
      //   element: <InsightsPage />
      // },
      // {
      //   path: 'notifications',
      //   element: <NotificationsPage />
      // },
      // { path: 'search', element: <SearchPage /> },
      // {
      //   path: 'directory',
      //   element: <DirectoryPage />
      // },
      // {
      //   path: 'messages',
      //   element: <MessagesPage />
      // },
      {
        path: 'profile',
        element: <ProfilePage />
      },
      {
        path: 'edit-profile',
        element: <EditProfilePage />
      },
      {
        path: 'edit-photo',
        element: <EditPhotoPage />,
        children: [
          {
            path: 'editor',
            element: <ImageEditor />
          },
          {
            path: 'upload',
            element: <ImageUploader />
          },
          {
            path: 'camera',
            element: <CameraUploader />
          }
        ]
      },
      {
        path: 'settings',
        element: <SettingsPage />
      },
      {
        path: 'contact',
        element: <ContactUsForm />
      },
      {
        path: 'bookmarked',
        element: <BookmarkedPage />
      },
      {
        path: '/',
        element: <ProgramsHomePage />
      },
      {
        path: '/:token/virtual',
        element: <VirtualPortal />
      },
      {
        path: '/:token/:tab/wizard',
        element: <Wizard />
      }
    ]
  },
  {
    path: 'programs',
    loader: checkAuthLoader,
    element: <MainLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true, // MVP
        element: <ProgramsHomePage />
      },
      {
        path: ':eventId',
        element: <ProgramsLayout />,
        children: [
          { index: true, element: <ProgramHomePage /> },
          { path: 'agenda', element: <AgendaIndex /> },
          { path: 'agenda/:sessionId', element: <AgendaIndex /> },
          { path: 'leaderboard', element: <Leaderboard /> },
          {
            path: 'leaderboard-ranking',
            element: <LeaderboardRanking />
          },
          { path: 'sponsors', element: <PartnerList /> },
          {
            path: 'sponsors/:partnerId',
            element: <PartnerDetailPage />
          },
          { path: 'support', element: <SupportPage /> },
          { path: 'attendees', element: <AttendeeIndex /> },
          { path: 'governing-body', element: <GoverningBodyIndex /> },
          { path: 'speakers', element: <SpeakerIndex /> },
          {
            path: 'attendees/:attendeeId',
            element: <UserDetailPage />
          },
          {
            path: 'event-evaluation/:evalId',
            element: <EventEvaluation />
          },
          {
            path: 'messages',
            element: <ThreadIndex />
          },
          {
            path: 'contact',
            element: <ContactUsForm />
          },
          {
            path: 'scanner',
            element: <Scanner />
          },
          {
            path: 'qr-code',
            element: <QRCodePage />
          }
        ]
      }
    ]
  }
])

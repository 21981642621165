import React from 'react'
import { Link } from 'react-router-dom'
import { isEmpty } from 'lodash'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import UserRow from 'src/components/Elements/UserRow'
import { ConditionalWrapper } from 'src/utils/ConditionalWrapper'
import StarRating from './StarRating'

const UserEvaluation = ({
  eventId,
  user,
  userType,
  userLabel,
  updateRatingMethod,
  userRating,
  ratingId,
  testId
}) => {
  if (isEmpty(user)) return <></>
  return (
    <div key={`user-eval-${user?.user_id}`}>
      <div data-testid={testId} className={generalStyles.userRowButton}>
        <ConditionalWrapper
          condition={user}
          wrapper={(children) => (
            <Link to={`/programs/${eventId}/attendees/${user?.user_id}`}>
              {children}
            </Link>
          )}
        >
          <UserRow
            key={user?.user_id}
            user={user}
            textContent={userType}
            speakerType={userLabel}
            isSmallPhoto={true}
          />
        </ConditionalWrapper>
      </div>
      <StarRating
        itemId={ratingId}
        updateRating={updateRatingMethod}
        rating={userRating}
        refId={testId}
      />
    </div>
  )
}

export default UserEvaluation

import React, { useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import IcomoonReact from 'icomoon-react'
import { approvedStatuses } from 'src/utils/helpers/EventHelperMethods'
import { useGlobalContext } from 'src/stores/GlobalContext'
import { unreadMessagesCount } from 'src/utils/helpers/MessagesHelpers'
import iconSet from 'src/assets/icons/iconList.json'
import variables from 'src/assets/styles/variables.module.scss'
import fontStyles from 'src/assets/styles/fontStyles.module.scss'
import generalStyles from 'src/assets/styles/generalStyles.module.scss'
import useClickOutside from 'src/hooks/useClickOutside'
import useFetchMessages from 'src/queries/messages/useFetchMessages'
import navStyles from './navigation.module.scss'
import CustomNavLink from './components/CustomNavLink'
import Fab from '../programs/program/fab/fabIndex'

const BackButton = ({ isDown, isNavOpen, handleBackClick }) => (
  <button
    data-title="Back to all Programs"
    className={classNames(navStyles.iconContainer)}
    onClick={() => handleBackClick()}
  >
    <IcomoonReact
      iconSet={iconSet}
      color={variables.white}
      size={16}
      icon={isDown
        ? isNavOpen
          ? 'Control-CloseCarat-Large-BlueOutline'
          : 'Control-OpenCarat-Large-BlueOutline'
        : 'Control-BackCarat-Large-GreyOutline'
      }
    />
  </button>
)

const ProgramsNavigation = ({ isMobile }) => {
  const clickRef = useRef()
  const navigate = useNavigate()
  const location = useLocation()
  const [isNavOpen, setIsNavOpen] = useState(!isMobile)

  useClickOutside(clickRef, () => setIsNavOpen(false))

  const handleBackClick = () => {
    navigate('/programs')
  }
  const { eventId, currentEvent, currentUser } = useGlobalContext()
  const { messages } = useFetchMessages()
  const unreadMessages = unreadMessagesCount(messages, currentUser)
  const isApprovedInternal = useMemo(() => currentEvent.registration_status === 'Approved Internal', [currentEvent])

  // Prior to Event Starting / If Not Attendee
  const preEventNavItems = [
    {
      name: 'Home',
      isDefaultLink: true,
      linkAddress: 'programs',
      hoverTitle: 'Home',
      iconName: 'HomeIcon-Medium-White',
      badge: false,
      gaTracking: true
    },
    {
      name: 'Agenda',
      isDefaultLink: false,
      linkAddress: 'agenda',
      hoverTitle: 'Agenda',
      iconName: 'AgendaIcon-Medium-White',
      badge: false,
      gaTracking: true
    },
    {
      name: 'Speakers',
      isDefaultLink: false,
      linkAddress: 'speakers',
      hoverTitle: 'Speakers',
      iconName: 'SpeakerIcon-Medium-White',
      badge: false,
      gaTracking: true
    },
    {
      name: 'GB',
      isDefaultLink: false,
      linkAddress: 'governing-body',
      hoverTitle: 'Governing Body',
      iconName: 'GBIcon-Medium-White',
      badge: false,
      gaTracking: true
    },
    {
      name: 'Sponsors',
      isDefaultLink: false,
      linkAddress: 'sponsors',
      hoverTitle: 'Sponsors',
      iconName: 'PartnerIcon-Medium-White',
      badge: false,
      gaTracking: true
    }
  ]
  // Event Started && Is an Attendee
  const eventNavItems = [
    {
      name: 'Home',
      isDefaultLink: true,
      hoverTitle: 'Home',
      iconName: 'HomeIcon-Medium-White',
      visible: true,
      badge: false,
      gaTracking: true
    },
    {
      name: 'Agenda',
      isDefaultLink: false,
      linkAddress: 'agenda',
      hoverTitle: 'Agenda',
      iconName: 'AgendaIcon-Medium-White',
      visible: true,
      badge: false,
      gaTracking: true
    },
    {
      name: 'Attendees',
      isDefaultLink: false,
      linkAddress: 'attendees',
      hoverTitle: 'Attendees',
      iconName: 'AttendeeIcon-Medium-White',
      visible: currentEvent?.is_attended,
      badge: false,
      gaTracking: true
    },
    {
      name: 'Leaderboard',
      isDefaultLink: false,
      linkAddress: 'leaderboard',
      hoverTitle: 'Leaderboard',
      iconName: 'LeaderboardIcon-Medium-White',
      visible: currentEvent?.leaderboard_present && currentEvent?.is_attended,
      badge: false,
      gaTracking: true
    },
    {
      name: 'Sponsors',
      isDefaultLink: false,
      linkAddress: 'sponsors',
      hoverTitle: 'Sponsors',
      iconName: 'PartnerIcon-Medium-White',
      visible: true,
      badge: false,
      gaTracking: true
    },
    {
      name: 'Messages',
      isDefaultLink: false,
      linkAddress: 'messages',
      hoverTitle: 'Messages',
      iconName: 'MessageIcon-Medium-Blue',
      visible: currentEvent?.is_attended && !isApprovedInternal,
      badge: unreadMessages > 0
    }
  ]

  const renderNavItems = () => {
    const navItems = []
    if (!currentEvent?.is_attended) {
      preEventNavItems.forEach((navItem) => {
        return navItems.push(
          <CustomNavLink
            key={navItem.hoverTitle}
            name={navItem.name.toLowerCase()}
            isDefaultLink={navItem.isDefaultLink}
            linkAddress={navItem.linkAddress}
            hoverTitle={navItem.hoverTitle}
            iconName={navItem.iconName}
            eventId={eventId}
            badge={navItem.badge}
            handleGaTracking={navItem.gaTracking}
            gaPrefix='pre_reg'
            isMobile={isMobile}
            closeMenu={() => setIsNavOpen(false)}
          />
        )
      })
    } else {
      eventNavItems.forEach((navItem) => {
        if (navItem.visible) {
          return navItems.push(
            <CustomNavLink
              key={navItem.name}
              name={navItem.name.toLowerCase()}
              isDefaultLink={navItem.isDefaultLink}
              linkAddress={navItem.linkAddress}
              hoverTitle={navItem.hoverTitle}
              iconName={navItem.iconName}
              eventId={eventId}
              badge={navItem.badge}
              handleGaTracking={navItem.gaTracking}
              gaPrefix='reg'
              isMobile={isMobile}
              closeMenu={() => setIsNavOpen(false)}
            />
          )
        }
      })
    }
    return navItems
  }

  if (isMobile) {
    return (
      <>
        <div
          className={navStyles.programsNavContainerMobile}
          ref={clickRef}
        >
          <div className={classNames(navStyles.eventNameContainer)}>
            <span
              className={fontStyles.h6variant2demilight}
              style={{ fontWeight: 600 }}
            >
              {currentEvent?.name}
            </span>
          </div>
          <BackButton
            isNavOpen={isNavOpen}
            isDown
            handleBackClick={() => setIsNavOpen(!isNavOpen)}
          />
          {isNavOpen && <div className={navStyles.navItemsMobile}>{renderNavItems()}</div>}
        </div>
        {!location.pathname.includes('/messages') &&
            approvedStatuses(currentEvent) &&
             <Fab />}
      </>
    )
  }

  return (
    <div className={classNames(navStyles.headerNavContainer, navStyles.programsNavContainer)}>
      <BackButton handleBackClick={handleBackClick} />
      <div className={classNames(generalStyles.pl20, navStyles.eventNameContainer)}>
        <span className={fontStyles.bodyboldlight}>{currentEvent?.name}</span>
      </div>
      {renderNavItems()}
      {!location.pathname.includes('/messages') &&
        approvedStatuses(currentEvent) &&
         <Fab />}
    </div>
  )
}

export default ProgramsNavigation
